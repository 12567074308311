var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-main',{attrs:{"title":"Activities, Awards, Projects, and Honors","aside":[
    'What did you do in school that helps you stand out?',
    'Did you serve in any leadership positions? Receive any awards that you would highlight?',
    'Enter any competitions? What about projects you worked on?',
    'If you are not sure where an item would go, don\'t worry! Pick a spot, record it, and keep thinking!',
    'If you have been out of school for a long time, or you don\'t want to list anything, you can leave this section blank.'
  ],"nextText":"Develop these items into lines for your resume","buttonTabIndex":"5","eventName":"nav"},on:{"nav":function($event){return _vm.nav('/EducationEntryAwardFocus/' + _vm.key)},"save":function($event){return _vm.save()}}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"r-col",attrs:{"cols":"12"}},[_c('r-multi-select',{attrs:{"label":"Activities","titleText":"Activities: ","optionsList":[],"selected":_vm.activities,"tabindex":"1","autofocus":""},on:{"update:selected":function($event){_vm.activities=$event}}})],1),_c('v-col',{staticClass:"r-col",attrs:{"cols":"12"}},[_c('r-multi-select',{attrs:{"label":"Awards","titleText":"Awards: ","optionsList":[],"selected":_vm.awards,"tabindex":"2"},on:{"update:selected":function($event){_vm.awards=$event}}})],1),_c('v-col',{staticClass:"r-col",attrs:{"cols":"12"}},[_c('r-multi-select',{attrs:{"label":"Projects","titleText":"Projects: ","optionsList":[],"selected":_vm.projects,"tabindex":"3"},on:{"update:selected":function($event){_vm.projects=$event}}})],1),_c('v-col',{staticClass:"r-col",attrs:{"cols":"12"}},[_c('r-multi-select',{attrs:{"label":"Honors","titleText":"Honors: ","optionsList":[],"selected":_vm.honors,"tabindex":"4"},on:{"update:selected":function($event){_vm.honors=$event}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }